import Rebase from 're-base';
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyD94goNwSdcV1vPJCaHIWzHRn1OTTNWZbg",
    authDomain: "catch-o-the-day-haydens.firebaseapp.com",
    databaseURL: "https://catch-o-the-day-haydens.firebaseio.com"
  });

  const base = Rebase.createClass(firebaseApp.database());

  //this is a named export
  export { firebaseApp};

  //this is a default export
  export default base;
